<template>
  <FeathersVuexFind
    service="cases"
    :fetchQuery="fetchQuery"
    :params="params"
    watch="params"
    :queryWhen="Number.isInteger(scenario_id)"
  >
    <template #default="{ items }">
      <InputSelect
        label="Case"
        v-model="case_id"
        :search-input.sync="searchInput"
        :items="items"
        item-text="name"
        item-value="id"
        :loading="caseLoadPending || caseSavePending"
        v-bind="$attrs"
        :save-as-rules="saveAsRules"
        :rename-rules="renameRules"
        @create="create"
        @rename="rename"
        @save-as="$emit('save-as', $event)"
      ></InputSelect>
    </template>
  </FeathersVuexFind>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { FeathersVuexFind } from "feathers-vuex";

export default {
  name: "SelectCase",
  props: {
    scenario_id: {
      type: Number,
      required: false,
      default: null,
    },
    value: {
      type: Number,
      required: false,
      default: null,
    },
  },
  components: {
    InputSelect: () => import("@/components/InputSelect"),
    FeathersVuexFind,
  },
  data() {
    return {
      searchInput: null,
      saveAsRules: [(value) => !/[/\\:*?<>|"']/g.test(value)],
      renameRules: [(value) => !/[/\\:*?<>|"']/g.test(value)],
      select: ["id", "name", "scenario_id"],
    };
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      getCases: "cases/find",
      caseLoadPending: "cases/loadPending",
      caseSavePending: "cases/savePending",
    }),
    fetchQuery() {
      const query = {
        name: {
          $like: `${this.searchInput}%`,
        },
      };
      if (this.scenario_id != null) {
        query.scenario_id = this.scenario_id;
      }
      return query;
    },
    params() {
      return { query: { scenario_id: this.scenario_id, $select: this.select } };
    },
    listCases() {
      if (Number.isInteger(this.scenario_id)) {
        return this.getCases({
          query: { scenario_id: this.scenario_id, $select: this.select },
          pagination: false,
        }).data;
      }
      return [];
    },
    case_id: {
      get() {
        return this.value;
      },
      set(id) {
        this.$emit("input", id);
      },
    },
  },
  watch: {
    scenario_id(to) {
      if (!this.listCases.length && Number.isInteger(this.scenario_id)) {
        this.loadCases({ query: { scenario_id: to } });
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions({
      loadCases: "cases/find",
      fetchCase: "cases/get",
    }),
    init() {
      if (Number.isInteger(this.scenario_id)) {
        this.loadCases({ query: { scenario_id: this.scenario_id, $select: this.select } });
      }
      if (this.case_id != null) {
        this.fetchCase(this.case_id);
      }
    },
    create(name) {
      this.$store
        .dispatch("cases/create", {
          name,
          scenario_id: this.scenario_id,
        })
        .then((_case) => {
          this.$store.dispatch("scenarios/get", _case.scenario_id);
        });
    },
    rename(name) {
      this.$store.dispatch("cases/patch", [this.case_id, { name }]);
    },
  },
};
</script>

<style></style>
